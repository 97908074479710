import React from "react";
import { Link } from "gatsby";

import Logo from "../images/inlinesvgs/logo.svg";
import EuFlag from "../images/inlinesvgs/eu-flag.svg";

const Footer = () => (
	<footer className="footer">
		<div className="container">
			<div className="footer-content">
				<div className="footer-description">
					<Link to="/" style={{ display: "inline-block" }} className="mb-20">
						<Logo className="logo" width={120} />
					</Link>

					<div className="">
						<div className="mb-10">
							Sidemail is an all-in-one email platform for SaaS. Estd. 2018.
							Brought to you by the team behind{" "}
							<a href="https://robolly.com/">Robolly</a> and{" "}
							<a href="https://emailsupportai.com/">EmailSupportAI</a>.
						</div>
						<div className="mb-20">support@sidemail.io</div>
						<div className="itemCenterVertical">
							<EuFlag
								width={20}
								style={{ display: "inline-block" }}
								className="mr-10"
							/>{" "}
							Made in EU. GDPR compliant.
						</div>
					</div>
				</div>

				<div style={{ maxWidth: 160 }} className="footer-column">
					<div className="footer-title">Sidemail</div>

					<div className="footer-item">
						<Link to="/pricing/">Pricing</Link>
					</div>

					<div className="footer-item">
						<Link to="/customer-reviews/">Customer reviews</Link>
					</div>

					<div className="footer-item">
						<Link to="/about/">About Sidemail</Link>
					</div>

					<div className="footer-item">
						<Link to="/articles/">Articles</Link>
					</div>

					<div className="footer-item">
						<Link to="/press/">Press & Media</Link>
					</div>

					<div className="footer-item">
						<Link to="/contact/">Contact us</Link>
					</div>
				</div>

				<div className="footer-column">
					<div className="footer-title">Popular solutions</div>

					<div className="footer-item">
						<a href="/email-sending-api/">Email sending API</a>
					</div>

					<div className="footer-item">
						<a href="/targeted-email-messages/">Messenger</a>
					</div>

					<div className="footer-item">
						<a href="/automated-email-sequences/">Email automation</a>
					</div>

					<div className="footer-item">
						<a href="/no-code-email-editor/">No-code email editor</a>
					</div>

					<div className="footer-item">
						<a href="/transactional-email-templates/">
							Transactional email templates
						</a>
					</div>
				</div>

				<div style={{ minWidth: 200 }} className="footer-column">
					<div className="footer-title">For developers</div>

					<div className="footer-item">
						<a href="/docs/email-sending-quickstart/">
							Email sending quickstart
						</a>
					</div>

					<div className="footer-item">
						<a href="/docs/contact-profiles-quickstart/">
							Contact profiles quickstart
						</a>
					</div>

					<div className="footer-item">
						<a href="/docs/api/authentication/">Full API reference</a>
					</div>

					<div className="footer-item">
						<Link to="/email-open-tracking/">Email open tracking</Link>
					</div>

					<div className="footer-item">
						<a
							href="https://stats.uptimerobot.com/gpPQWiyqL"
							target="_blank"
							rel="nofollow noopener noreferrer">
							API status
						</a>
					</div>
				</div>

				<div style={{}}>
					<div className="footer-title">Legal</div>

					<div className="footer-item">
						<Link to="/legal/privacy-policy/">Privacy Policy</Link>
					</div>

					<div className="footer-item">
						<Link to="/legal/terms-of-service/">Terms of Service</Link>
					</div>

					<div className="footer-item">
						<Link to="/legal/data-processing-agreement/">
							Data Processing Agreement
						</Link>
					</div>

					<div className="footer-item">
						<Link to="/anti-spam-policy/">Anti-spam Policy</Link>
					</div>
				</div>
			</div>

			<div className="footer-copyright text-center text-xxsmall">
				Copyright © {new Date().getFullYear()} Sidemail.io. All rights reserved.
			</div>
		</div>
	</footer>
);

export default Footer;
