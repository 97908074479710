import React from "react";
import { Link } from "gatsby";

import HamburgerIcon from "../images/inlinesvgs/icon-hamburger.svg";
import XMarkIcon from "../images/inlinesvgs/icon-xmark.svg";
import IconPanak from "../images/inlinesvgs/icons/panak.svg";
import IconApi from "../images/inlinesvgs/icons/api.svg";
import IconEmail from "../images/inlinesvgs/icons/email.svg";
import IconRaketa from "../images/inlinesvgs/icons/raketa.svg";
import IconSipka from "../images/inlinesvgs/icons/sipka.svg";
import IconsZavinac from "../images/inlinesvgs/icons/zavinac.svg";
import IconEditor from "../images/inlinesvgs/icons/editor.svg";

class MobileNav extends React.Component {
	state = {
		isOpen: false,
	};

	handleOpen = () => {
		this.setState({ isOpen: true });

		document.body.style.overflowY = "hidden";
	};

	handleClose = () => {
		this.setState({ isOpen: false });

		document.body.style.overflowY = null;
	};

	render() {
		const sharedLinkProps = {
			className: "nav-link",
			onClick: this.handleClose,
		};

		return (
			<div className="nav-mobile">
				<button
					className="button button--bright button--outline button--borderLess"
					onClick={this.handleOpen}>
					<HamburgerIcon />
				</button>

				{this.state.isOpen && (
					<div className="nav-mobile-overlay">
						<div className="closeContainer">
							<button
								className="button button--bright button--outline button--borderLess"
								onClick={this.handleClose}>
								<XMarkIcon />
							</button>
						</div>

						<div className="nav-section">
							<div className="textLabel text-light u-mb2">Solutions</div>
							<Link {...sharedLinkProps} to="/email-sending-api/">
								<IconApi height={18} className="nav-mobile-icon" />
								Email sending API
							</Link>

							<Link {...sharedLinkProps} to="/targeted-email-messages/">
								<IconSipka height={21} className="nav-mobile-icon" />
								Messenger
							</Link>

							<Link {...sharedLinkProps} to="/automated-email-sequences/">
								<IconRaketa height={22} className="nav-mobile-icon" />
								Automated email sequences
							</Link>

							<Link {...sharedLinkProps} to="/subscribe-forms/">
								<IconsZavinac height={20} className="nav-mobile-icon" />
								Subscribe forms
							</Link>

							<Link {...sharedLinkProps} to="/contact-profiles/">
								<IconPanak height={22} className="nav-mobile-icon" />
								Contact profiles
							</Link>

							<Link {...sharedLinkProps} to="/no-code-email-editor/">
								<IconEditor height={25} className="nav-mobile-icon" />
								No-code email editor
							</Link>

							<Link {...sharedLinkProps} to="/transactional-email-templates/">
								<IconEmail height={19} className="nav-mobile-icon" />
								Transactional email templates
							</Link>
						</div>

						<hr className="separator" />

						<Link {...sharedLinkProps} to="/pricing/">
							Pricing
						</Link>

						<Link {...sharedLinkProps} to="/customer-reviews/">
							Customer reviews
						</Link>

						<a className="nav-link" href="/docs/email-sending-quickstart">
							Documentation
						</a>

						<a
							className="header-signup"
							href="https://client.sidemail.io/register">
							Start 7-day trial
						</a>

						<a
							className="header-login button button--bright button--outline"
							href="https://client.sidemail.io/login">
							Login
						</a>
					</div>
				)}
			</div>
		);
	}
}

export default MobileNav;
